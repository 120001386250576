import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{attrs:{"permanent":"","mini-variant":_vm.mini,"expand-on-hover":"","width":"220","color":"menu","dark":"","app":"","clipped":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":""}},[_vm._l((_vm.menuFiltrado),function(item,index){return [(!item.group)?_c(VListItem,{key:`menuItem-${index}`,attrs:{"link":""},on:{"click":function($event){return _vm.redirectMenu(item.route)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))]),(item.subtitle)?_c(VListItemSubtitle,[_vm._v(_vm._s(item.subtitle))]):_vm._e()],1)],1):_c(VListGroup,{key:`group-${index}`,attrs:{"value":false,"prepend-icon":item.icon,"no-action":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)]},proxy:true}],null,true)},_vm._l((item.items),function(subitem,index){return _c(VListItem,{key:`subitem-${index}`,staticStyle:{"padding-left":"55px"},on:{"click":function($event){return _vm.redirectMenu(subitem.route)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(subitem.title))])],1)],1)}),1)]}),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.cerrrarSesion()}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-power")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Cerrar sesión")])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }