import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{attrs:{"color":"primary","dark":"","flat":"","app":"","clipped-left":"","clipped-right":""}},[_c(VAvatar,{staticStyle:{"margin-right":"10px"},attrs:{"size":"35"}},[(!_vm.userAvatar)?_c(VIcon,{attrs:{"size":"30"}},[_vm._v("mdi-account")]):_c('img',{attrs:{"src":`${_vm.imageURL}/icon/${_vm.userAvatar}`,"alt":"User"}})],1),_c(VSpacer),_c(VToolbarTitle,[_c('h3',[_vm._v(_vm._s(_vm.appName))])]),_c(VSpacer),(_vm.apps.length > 0)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDrawer()}}},[_c(VIcon,[_vm._v("mdi-menu")])],1):_vm._e(),_c(VProgressLinear,{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","bottom":"","color":"white"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }